var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-sheet",
    {
      staticClass:
        "d-flex align-center bg-white border-0 border-t border-l border-solid border-primary",
      staticStyle: { position: "fixed", bottom: "0", right: "0" },
      attrs: {
        width: _vm.$vuetify.breakpoint.width - _vm.$vuetify.application.left,
        height: "64",
      },
    },
    [
      _c(
        "v-row",
        {
          staticClass: "px-15",
          staticStyle: { width: "100%" },
          attrs: { "no-gutters": "" },
        },
        [
          _c(
            "v-col",
            { staticClass: "d-flex align-center", attrs: { cols: "6" } },
            [
              _c(
                "v-slide-group",
                {
                  staticClass: "slide-group-component",
                  attrs: { multiple: "", "show-arrows": "" },
                },
                _vm._l(_vm.items, function (item, idx) {
                  return _c(
                    "v-slide-item",
                    { key: idx },
                    [
                      _c(
                        "v-sheet",
                        {
                          staticClass:
                            "clickable rounded pa-2 mr-2 my-1 d-flex align-center justify-center",
                          class: [
                            _vm.colorClass(item),
                            _vm.highlightClass(item),
                          ],
                          attrs: { height: "32", "min-width": "32" },
                          on: {
                            click: function ($event) {
                              return _vm.moveTo(item)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.index) + " ")]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pl-5 d-flex align-center justify-space-between",
              attrs: { cols: "2" },
            },
            [
              _c("span", [_vm._v("Total Marks:")]),
              _c("span", [
                _vm._v(_vm._s(_vm.totalScore) + "/" + _vm._s(_vm.totalPoints)),
              ]),
            ]
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center justify-end",
              attrs: { cols: "4" },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "primary white--text xl:text-lg lg:text-lg md:text-base sm:text-base",
                  attrs: {
                    loading: _vm.loading,
                    depressed: "",
                    height: "40",
                    width: "140",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("submit")
                    },
                  },
                },
                [_vm._v("Confirm ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }