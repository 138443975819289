<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        <h1 class="xl:text-3xl">Exam Details</h1>
      </v-col>
    </v-row>
    <fluent-assess-exam
      v-model="score"
      :loading="loading['exam']"
    ></fluent-assess-exam>
    <exam-details-bottom-nav
      :score="score"
      :loading="loading['evaluating']"
      @jump="jumpToQuestion"
      @submit="submitScore()"
    ></exam-details-bottom-nav>
  </v-container>
</template>

<script>
import { computed } from "vue";
import { apiCallUtils } from "/global/utils/mixins";
import ExamDetailsBottomNav from "#ecf/exam/components/FluentExamDetailsBottomNav.vue";
import FluentAssessExam from "#ecf/exam/components/FluentAssessExam.vue";

export default {
  name: "FluentStudentExamDetails",
  components: {
    FluentAssessExam,
    ExamDetailsBottomNav
  },
  mixins: [apiCallUtils],
  data() {
    return {
      exam: undefined,
      current: undefined,
      loading: {},
      score: {}
    };
  },
  computed: {
    sizes() {
      return {
        container: {
          height:
            this.$vuetify.breakpoint.height -
            this.$vuetify.application.top -
            24,
          width:
            this.$vuetify.breakpoint.width - this.$vuetify.application.left - 24
        }
      };
    }
  },
  async created() {
    await this.waitingCall(this.getStudentExamDetails, this.loading, "exam");
    this.exam?.questions?.sort((first, second) => first.order - second.order);
    let questionItem = this.exam?.questions.at(0);
    this.current = {
      id: questionItem?.id,
      essayId: questionItem?.essay_id
    };
  },
  provide() {
    return {
      exam: computed(() => this.exam),
      current: computed(() => this.current),
      navigated: this.navigated
    };
  },
  methods: {
    navigated(id) {
      if (id === this.current.id) return;
      let questionItem = this.exam?.questions?.find(item => item.id === id);
      if (questionItem !== undefined) {
        this.current.id = questionItem.id;
        this.current.essay_id = questionItem.essay_id;
      }
    },
    jumpToQuestion(payload) {
      this.current = payload;
    },
    async getStudentExamDetails() {
      this.exam = await this.$store.dispatch("exam/getExamHistoryDetails", {
        studentExamId: this.$attrs.studentExamId
      });
      for (let item in this.exam?.answers) {
        if (
          typeof this.exam.answers[item] === "object" &&
          Boolean(this.exam.answers[item])
        ) {
          this.$set(
            this.score,
            item,
            `${parseInt(this.exam.answers[item].score)}` || undefined
          );
        }
      }
    },
    async submitScore() {
      if (this.loading["evaluating"]) return;
      let questionIndex = undefined;
      try {
        this.$set(this.loading, "evaluating", true);
        for (let questionId in this.score) {
          questionIndex = this.exam?.questions.findIndex(
            item => item.id === questionId
          );
          await this.$store.dispatch("exam/setScore", {
            studentExamId: this.$attrs.studentExamId,
            score: String(this.score[questionId]),
            questionId
          });
        }
        if (Object.keys(this.score).length === 0) {
          await this.$store.dispatch("exam/setEvaluation", {
            studentExamId: this.$attrs.studentExamId
          });
        }
        await this.$router.push({ name: "exam-batches" });
      } catch (e) {
        let message = questionIndex
          ? `Question ${questionIndex}: ${e.message}`
          : e.message;
        this.$root.$emit("alert", [undefined, message]);
      } finally {
        this.$set(this.loading, "evaluating", false);
      }
    }
  }
};
</script>
