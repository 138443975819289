var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c("div", [_vm._m(0)])
    : _vm.tabItems !== undefined
    ? _c(
        "div",
        [
          _vm.splitView
            ? _c(
                "v-tabs-items",
                {
                  staticClass: "transparent",
                  model: {
                    value: _vm.tabIndex,
                    callback: function ($$v) {
                      _vm.tabIndex = $$v
                    },
                    expression: "tabIndex",
                  },
                },
                _vm._l(_vm.tabItems, function (tab, index) {
                  return _c(
                    "v-tab-item",
                    { key: index },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          tab.essayContent
                            ? _c(
                                "v-col",
                                {
                                  class: _vm.classes.essay,
                                  attrs: { cols: _vm.cols.essay },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "split-area bg-white",
                                      class: _vm.classes.splitArea,
                                    },
                                    [
                                      _c("essay-viewer", {
                                        attrs: {
                                          "essay-content": tab.essayContent,
                                          outlined: false,
                                          reactive: true,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            {
                              class: _vm.classes.questionGroup,
                              attrs: { cols: _vm.cols.questionGroup },
                            },
                            [
                              tab.isAdvance
                                ? _c(
                                    "div",
                                    { staticClass: "split-area" },
                                    _vm._l(
                                      tab.questionGroups,
                                      function (content, i) {
                                        return _c(
                                          "div",
                                          {
                                            key: i,
                                            staticClass: "bg-white",
                                            class: [
                                              _vm.classes.splitArea,
                                              { "mt-10": i > 0 },
                                            ],
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "ft-bold xl:text-lg",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(content.heading) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "question-group-viewer",
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    record: _vm.record,
                                                    readonly: true,
                                                  },
                                                  on: {
                                                    "submit-score":
                                                      _vm.recordScore,
                                                  },
                                                },
                                                "question-group-viewer",
                                                content,
                                                false
                                              )
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "split-area fluent-question-display",
                                    },
                                    _vm._l(
                                      tab.questionContent,
                                      function (question, i) {
                                        return _c("question-answer-card-p", {
                                          key: i,
                                          staticStyle: { padding: "0" },
                                          attrs: {
                                            id: `question-${question.id}`,
                                            question: question,
                                            readonly: true,
                                            value: _vm.recordNewToOld(
                                              _vm.record[question.id],
                                              question.type
                                            ),
                                          },
                                          on: {
                                            "submit-score": (v) =>
                                              _vm.recordScore({
                                                [question.id]: v,
                                              }),
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _c(
                "div",
                { staticClass: "single-area d-flex flex-column align-center" },
                _vm._l(_vm.tabItems, function (tab, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "ma-5 d-flex flex-column align-center",
                      style: _vm.styles.singleView,
                    },
                    [
                      tab.essayContent
                        ? _c(
                            "div",
                            { staticStyle: { width: "100%" } },
                            [
                              _c("essay-viewer", {
                                staticClass: "elevation-1",
                                attrs: {
                                  "essay-content": tab.essayContent,
                                  outlined: false,
                                  reactive: true,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { style: _vm.styles.singleArea },
                        _vm._l(tab.questionContent, function (question, i) {
                          return _c("question-answer-card-p", {
                            key: i,
                            attrs: {
                              id: `question-${question.id}`,
                              question: question,
                              readonly: true,
                              value: _vm.recordNewToOld(
                                _vm.record[question.id],
                                question.type
                              ),
                            },
                            on: {
                              "submit-score": (v) =>
                                _vm.recordScore({ [question.id]: v }),
                            },
                          })
                        }),
                        1
                      ),
                    ]
                  )
                }),
                0
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { attrs: { id: "loading-wrapper" } }, [
      _c("div", { attrs: { id: "loading-text" } }, [_vm._v("LOADING")]),
      _c("div", { attrs: { id: "loading-content" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }