var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "3" } }, [
            _c("h1", { staticClass: "xl:text-3xl" }, [_vm._v("Exam Details")]),
          ]),
        ],
        1
      ),
      _c("fluent-assess-exam", {
        attrs: { loading: _vm.loading["exam"] },
        model: {
          value: _vm.score,
          callback: function ($$v) {
            _vm.score = $$v
          },
          expression: "score",
        },
      }),
      _c("exam-details-bottom-nav", {
        attrs: { score: _vm.score, loading: _vm.loading["evaluating"] },
        on: {
          jump: _vm.jumpToQuestion,
          submit: function ($event) {
            return _vm.submitScore()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }