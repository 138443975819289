<template>
  <v-sheet
    :width="$vuetify.breakpoint.width - $vuetify.application.left"
    height="64"
    class="d-flex align-center bg-white border-0 border-t border-l border-solid border-primary"
    style="position: fixed; bottom: 0; right: 0;"
  >
    <v-row no-gutters class="px-15" style="width: 100%">
      <v-col cols="6" class="d-flex align-center">
        <v-slide-group multiple show-arrows class="slide-group-component">
          <v-slide-item v-for="(item, idx) in items" :key="idx">
            <v-sheet
              :class="[colorClass(item), highlightClass(item)]"
              class="clickable rounded pa-2 mr-2 my-1 d-flex align-center justify-center"
              height="32"
              min-width="32"
              @click="moveTo(item)"
              >{{ item.index }}
            </v-sheet>
          </v-slide-item>
        </v-slide-group>
      </v-col>
      <v-col cols="2" class="pl-5 d-flex align-center justify-space-between">
        <span>Total Marks:</span>
        <span>{{ totalScore }}/{{ totalPoints }}</span>
      </v-col>
      <v-col cols="4" class="d-flex align-center justify-end">
        <v-btn
          :loading="loading"
          depressed
          height="40"
          width="140"
          class="primary white--text xl:text-lg lg:text-lg md:text-base sm:text-base"
          @click="$emit('submit')"
          >Confirm
        </v-btn>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "ExamDetailsBottomNav",
  inject: ["exam", "current"],
  props: {
    score: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    items() {
      let x = this.exam?.questions?.map(item => ({
        index: item.order + 1,
        id: item.id,
        essayId: item.essay_id
      }));
      return x || [];
    },
    currentIndex() {
      return this.items.findIndex(item => item.id === this.current?.id);
    },
    totalScore() {
      return (
        this.exam?.questions?.reduce((total, item) => {
          let ans = this.exam?.answers[item.id];
          if (typeof ans === "object") {
            total += parseInt(this.score[item.id]) || 0;
            return total;
          } else if (typeof ans === "string") {
            total += this.colorClass(item) === "correct" ? item.points : 0;
            return total;
          } else return total;
        }, 0) || 0
      );
    },
    totalPoints() {
      return this.exam?.student_exam?.total_points || 0;
    }
  },
  methods: {
    moveTo(item) {
      this.$emit("jump", { id: item.id, essayId: item.essayId });
    },
    findAnswerTrueMCQ(userInput, answers) {
      let ansList = JSON.parse(userInput);
      let correctList = answers.map(item => item.id);
      let any = false;
      ansList.forEach(item => {
        any = any || correctList.includes(item);
      });
      return any;
    },
    findAnswerTrueAMCQ(userInput, answers) {
      let ansSet = new Set(JSON.parse(userInput));
      let correctSet = new Set(answers?.split(",") ?? []);

      let wrongAnswers = [];
      let missingAnswers = [];
      ansSet.forEach(item => {
        if (!correctSet.has(item)) wrongAnswers.push(item);
      });
      correctSet.forEach(item => {
        if (!ansSet.has(item)) missingAnswers.push(item);
      });
      return Boolean(wrongAnswers.length === 0 && missingAnswers.length === 0);
    },
    colorClass(item) {
      let userInput = this.exam?.answers[item.id];
      if (!userInput) return "normal";
      let questionItem = this.exam?.questions?.find(
        question => question.id === item.id
      );
      if (questionItem.answers[0] === undefined) return "answered";
      if (questionItem.sub_type) {
        let correctAnswers = questionItem.answers[0].item_text.split(",");
        return correctAnswers.includes(userInput) ? "correct" : "wrong";
      } else {
        switch (questionItem.type) {
          case "mcq":
            return this.findAnswerTrueMCQ(userInput, questionItem.answers)
              ? "correct"
              : "wrong";
          case "fib":
            return questionItem.answers[0].item_text === userInput
              ? "correct"
              : "wrong";
          case "desc":
            return "answered";
          case "amcq":
            return this.findAnswerTrueAMCQ(userInput, questionItem.answers[0].item_text)
              ? "correct"
              : "wrong";
          default:
            return "normal";
        }
      }
    },
    highlightClass(item) {
      if (item.id === this.current?.id) return "selected";
      else if (item.essayId === this.current?.essayId && item.essayId)
        return "in-group";
      else return "";
    }
  }
};
</script>

<style scoped lang="scss">
.slide-group-component::v-deep {
  .v-slide-group__content {
    padding: 0 4px;
  }
}

.normal {
  background-color: adjust-color(blue, $lightness: 46%);
  color: $primary;

  &.selected {
    outline: 2px solid $primary;
  }

  &.in-group {
    outline: 1px solid $primary;
  }
}

.answered {
  background-color: $primary;
  color: white;

  &.selected {
    outline: 2px solid darkblue;
  }

  &.in-group {
    outline: 1px solid darkblue;
  }
}

.correct {
  background-color: limegreen; //adjust-color(green, $lightness: 17%, $saturation: -10%);
  color: white;

  &.selected {
    outline: 2px solid darkgreen;
  }

  &.in-group {
    outline: 1px solid darkgreen;
  }
}

.wrong {
  background-color: $decline;
  color: white;

  &.selected {
    outline: 2px solid darkred;
  }

  &.in-group {
    outline: 1px solid darkred;
  }
}
</style>
