<template>
  <div v-if="loading">
    <div id="loading-wrapper">
      <div id="loading-text">LOADING</div>
      <div id="loading-content"></div>
    </div>
  </div>
  <div v-else-if="tabItems !== undefined">
    <!--    Split View-->
    <v-tabs-items v-if="splitView" v-model="tabIndex" class="transparent">
      <v-tab-item v-for="(tab, index) in tabItems" :key="index">
        <v-row no-gutters>
          <v-col
            v-if="tab.essayContent"
            :cols="cols.essay"
            :class="classes.essay"
          >
            <div class="split-area bg-white" :class="classes.splitArea">
              <essay-viewer
                :essay-content="tab.essayContent"
                :outlined="false"
                :reactive="true"
              ></essay-viewer>
            </div>
          </v-col>
          <v-col :cols="cols.questionGroup" :class="classes.questionGroup">
            <div v-if="tab.isAdvance" class="split-area">
              <div
                v-for="(content, i) in tab.questionGroups"
                :key="i"
                class="bg-white"
                :class="[classes.splitArea, { 'mt-10': i > 0 }]"
              >
                <div class="ft-bold xl:text-lg">
                  {{ content.heading }}
                </div>
                <question-group-viewer
                  :record="record"
                  v-bind="content"
                  :readonly="true"
                  @submit-score="recordScore"
                ></question-group-viewer>
              </div>
            </div>
            <div v-else class="split-area fluent-question-display">
              <question-answer-card-p
                v-for="(question, i) in tab.questionContent"
                :id="`question-${question.id}`"
                :key="i"
                style="padding: 0"
                :question="question"
                :readonly="true"
                :value="recordNewToOld(record[question.id], question.type)"
                @submit-score="v => recordScore({ [question.id]: v })"
              >
              </question-answer-card-p>
            </div>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
    <!--    Single View-->
    <div v-else class="single-area d-flex flex-column align-center">
      <div
        v-for="(tab, index) in tabItems"
        :key="index"
        :style="styles.singleView"
        class="ma-5 d-flex flex-column align-center"
      >
        <div v-if="tab.essayContent" style="width: 100%">
          <essay-viewer
            :essay-content="tab.essayContent"
            :outlined="false"
            :reactive="true"
            class="elevation-1"
          ></essay-viewer>
        </div>
        <div :style="styles.singleArea">
          <question-answer-card-p
            v-for="(question, i) in tab.questionContent"
            :id="`question-${question.id}`"
            :key="i"
            :question="question"
            :readonly="true"
            :value="recordNewToOld(record[question.id], question.type)"
            @submit-score="v => recordScore({ [question.id]: v })"
          >
          </question-answer-card-p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EssayViewer from "#ecf/question-bank/components/GroupQuestion/EssayViewer.vue";
import QuestionGroupViewer from "#ecf/question-bank/components/GroupQuestion/QuestionGroupViewer.vue";
import QuestionAnswerCardP from "#ecf/exam/components/QuestionAnswerCardP.vue";
import { ResponsivenessUtils } from "/global/utils/mixins.js";
import { scrollTo } from "/global/utils/helpers";

export default {
  inject: ["exam", "current"],
  components: {
    EssayViewer,
    QuestionGroupViewer,
    QuestionAnswerCardP
  },
  mixins: [ResponsivenessUtils],
  model: {
    prop: "score",
    event: "update:score"
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    score: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      tabIndex: 0
    };
  },
  computed: {
    splitView() {
      return this.exam?.student_exam?.view?.toLowerCase() === "split";
    },
    record() {
      return !this.loading ? this.exam?.answers || {} : {};
    },
    tabItems() {
      let splitItemList = [];
      let essayItem = this.exam?.essays?.reduce((acc, curr) => {
        acc[curr.id] = { essay: curr, questions: [], inList: false };
        return acc;
      }, {});
      this.exam?.questions?.forEach(item => {
        if ("essay_id" in item) {
          if (!essayItem[item.essay_id].inList)
            splitItemList.push(essayItem[item.essay_id]);
          essayItem[item.essay_id].questions.push(item);
          essayItem[item.essay_id].inList = true;
        } else {
          splitItemList.push({
            essay: undefined,
            questions: [item]
          });
        }
      });
      return splitItemList?.map(item => this.makeQuestionItem(item)) || [];
    },
    navMap() {
      return this.tabItems.reduce((acc, curr, index) => {
        if (curr.essayContent?.id) {
          acc[curr.essayContent.id] = index;
        } else {
          acc[curr.questionContent[0].id] = index;
        }
        return acc;
      }, {});
    },
    styles() {
      return {
        singleView: `width: ${this.breakPointValues(320, 800, 800, 1024)}px`,
        singleArea: `width: ${this.breakPointValues(320, 640, 640, 800)}px`
      };
    },
    classes() {
      return {
        essay: this.breakPointValues("mb-4", "mb-4", "pr-4", "pr-4"),
        questionGroup: this.breakPointValues("", "", "pl-4", "pl-4"),
        splitArea: this.breakPointValues("pa-4", "pa-6", "pa-6", "pa-8")
      };
    },
    cols() {
      return {
        title: this.breakPointValues("", "6", "9", "9"),
        time: this.breakPointValues("12", "6", "3", "3"),
        essay: this.breakPointValues("12", "12", "6", "6"),
        questionGroup: this.breakPointValues("12", "12", "6", "6")
      };
    }
  },
  watch: {
    current: {
      deep: true,
      handler(value) {
        if (this.splitView) {
          this.tabIndex = this.navMap[value.essayId || value.id];
          if ((this.tabIndex[this.tabIndex]?.isAdvance ?? false) === false)
            scrollTo(`question-${value.id}`, { block: "center" });
        } else {
          scrollTo(`question-${value.id}`, { block: "center" });
        }
      }
    }
  },
  methods: {
    questionHeading(item, subItem) {
      let questions = item.questions.filter(
        q => q.collection_id === subItem.id
      );
      let fistQuestionIndex = questions?.at(0)?.order + 1;
      let lastQuestionIndex = questions?.slice(-1)[0].order + 1;

      if (fistQuestionIndex < lastQuestionIndex)
        return `Question ${fistQuestionIndex} - ${lastQuestionIndex}`;
      else return `Question ${fistQuestionIndex}`;
    },
    recordNewToOld(payload, questionType) {
      if (!payload) return [];
      switch (questionType) {
        case "desc":
          return [payload];
        case "mcq":
          return JSON.parse(payload);
        case "fib":
          return [payload];
      }
    },
    recordScore(payload) {
      let value = Object.assign(this.score, payload);
      this.$emit("update:score", value);
    },
    makeQuestionItem(item) {
      if (item.essay?.question_group?.length ?? 0 > 0)
        return this.makeAdvancedQuestionItem(item);
      else if (item.essay) return this.makeGroupQuestionItem(item);
      else return this.makeSingleQuestionItem(item);
    },
    makeAdvancedQuestionItem(item) {
      let mapCollectionQuestions = item?.questions.reduce((acc, curr) => {
        let c = curr["collection_id"];
        if (c) {
          if (c in acc) {
            acc[c].push(curr);
          } else {
            acc[c] = [curr];
          }
        }
        return acc;
      }, {});

      return {
        isAdvance: true,
        essayContent: item.essay,
        questionGroups: item.essay.question_group
          .filter(g => g.id in mapCollectionQuestions)
          .map(g => ({
            heading: this.questionHeading(item, g),
            content: g.item_text,
            questions: mapCollectionQuestions[g.id],
            answers:
              this.exam?.options[item.essay.id]?.filter(
                a => a.collection_id === g.id
              ) ?? [],
            files: g.files,
            isHistory: true,
            isEvaluation: true
          }))
          .sort((a, b) => a.questions[0].order - b.questions[0].order)
      };
    },
    makeGroupQuestionItem(item) {
      return {
        isAdvance: false,
        essayContent: item.essay,
        questionContent: item.questions
      };
    },
    makeSingleQuestionItem(item) {
      return {
        isAdvance: false,
        questionContent: item.questions
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.split-area {
  overflow: scroll;
  max-height: 640px;
  scrollbar-width: auto;
  scrollbar-color: $primary white;
}

.single-area {
  overflow: scroll;
  max-height: 640px;
  scrollbar-width: auto;
  scrollbar-color: $primary white;
}

.fluent-question-display {
  div {
    margin-bottom: 24px;
  }

  :last-child {
    margin-bottom: 0;
  }
}
</style>
